<template>
  <b-modal
    size="lg"
    id="maintenance-service-cost"
    centered
    modal-class="no-header-modal"
    no-close-on-esc
    no-close-on-backdrop
    :hide-header="true"
  >
    <b-card-text>
      <!-- <div class="new-modal-title">
      </div> -->
      <div class="maintenance-service-title">
        {{ $t("Maintenance.ServicesAndCost") }}
      </div>
      <validation-observer ref="serviceRef" #default="{ invalid }">
        <b-form class="mtt-37">
          <b-row
            ><b-col sm="6">
              <b-form-group :label="$t('Maintenance.ServiceName')">
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  :rules="{
                    required: true
                  }"
                >
                  <v-select
                    id="vue-select-1"
                    :placeholder="$t('maintenance.SelectServices')"
                    :options="categories"
                    :reduce="(option) => option.value"
                    label="type"
                    :clearable="false"
                    :disabled="isEdit"
                    @input="changeType"
                    :multiple="false"
                    :class="!isEditable ? 'disabled' : ''"
                    v-model="service.type"
                    class="vs-icon"
                  >
                    <template #selected-option="{ type, icon }">
                      <b-avatar
                        :src="icon"
                        size="24"
                        variant="light-primary"
                        class="align-middle mr-50 unit-filter-avatar"
                      />

                      <span class="unit-search-w"> {{ type }}</span>
                    </template>
                    <template #option="{ type, icon }">
                      <b-avatar
                        :src="icon"
                        variant="light-primary"
                        size="24"
                        class="align-middle mr-50"
                      />

                      <span class="unit-search-w">{{ type }} </span>
                    </template>
                  </v-select>

                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group></b-col
            ><b-col sm="6">
              <b-form-group :label="$t('Maintenance.Cost')">
                <validation-provider
                  #default="{ errors }"
                  name="cost"
                  :rules="{
                    required: true,
                    min_value: 0
                  }"
                >
                  <b-form-input
                    id="cost"
                    type="number"
                    :min="0"
                    v-model="service.cost"
                  />
                  <small class="text-danger">{{
                    errors[0] ? handleError(errors[0]) : ""
                  }}</small>
                </validation-provider>
              </b-form-group></b-col
            ></b-row
          >
          <b-row v-if="service.fields && service.fields.length"
            ><b-col sm="12">
              <validation-observer ref="refVariables">
                <ParameterAlertComponents
                  :parameters="service.fields"
                  :isEditable="true"
                  v-model="service.fields"
                  component_key="maintenance"
                  @validationError="showValidationError"
              /></validation-observer> </b-col
          ></b-row>
          <div class="d-flex justify-content-center mb-64 mtt-50">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="invalid"
              @click="save()"
            >
              {{ $t("ReportsData.Save") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mt-2 mr-1"
              @click="close()"
            >
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </b-form></validation-observer
      >
    </b-card-text>
  </b-modal>
</template>
<script>
import {
  BCard,
  BFormInput,
  BButton,
  BFormGroup,
  BModal,
  BCardText,
  BForm,
  BRow,
  BCol,
  BAvatar
} from "bootstrap-vue";
import constants from "@/utils/constants";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MaintenanceService from "@/libs/api/maintenance-service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, min, max } from "@validations";
import ParameterAlertComponents from "@/views/DynamicComponents/ParameterAlertComponents.vue";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BFormGroup,
    BModal,
    BCardText,
    BForm,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    ParameterAlertComponents,
    vSelect,
    BAvatar
  },
  data() {
    return {
      service: {
        type: "",
        value: "",
        cost: 0,
        fields: []
      },
      isEdit: false,
      hasErr: "",
      isShow: false,
      servicesList: [
        "Oil Change,Engdddine Examination87829",
        "oil Change,Engine Examination87829"
      ]
    };
  },
  directives: {
    // "b-tooltip": VBTooltip,
    Ripple
  },
  mounted() {
    if (this.selectedService) {
      this.service = JSON.parse(JSON.stringify(this.selectedService));
      this.isEdit = true;

      // this.service = JSON.parse(JSON.stringify(this.selectedService));
    } else {
      this.service = {
        type: "",
        value: "",
        cost: 0,
        fields: []
      };
      this.isEdit = false;
      const selectedType = this.categories.find(
        (e) => e.value === this.selectedService.value
      );
      if (selectedType && selectedType.type) {
        this.service.fields = selectedType.fields;
      }
    }
  },
  props: ["reportData", "isEditable", "categories", "selectedService"],
  watch: {},
  methods: {
    async save() {
      this.service.cost = Number(this.service.cost);
      this.service.value = this.service.value;
      this.$emit("addUpdateService", {
        service: this.service,
        isEdit: this.isEdit
      });
    },
    changeType(type) {
      const selectedType = this.categories.find((e) => e.value === type);
      if (selectedType && selectedType.type) {
        this.service.fields = selectedType.fields;
        this.service.value = selectedType.value;
      }
    },
    close() {
      this.$emit("close", true);
    },
    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    showValidationError(error) {
      // this.validationError = error;
    },
    async getAllServiceCategories() {
      try {
        this.showLoading = true;
        let response = await new MaintenanceService().getAllServiceCategories();
        this.showLoading = false;
        if (response && response.data) {
          this.categories = response.data;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.you-invite {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: $body-color;
  margin-top: 14px;
}
.maintenance-service-title {
  font-family: Montserrat;
  font-size: 17.99px;
  font-weight: 600;
  line-height: 21.93px;
  text-align: center;
}
</style>
<style lang="scss">
.vs-icon {
  .vs__selected {
    margin-top: 5px !important;
  }
}
</style>
