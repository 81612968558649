<template>
  <div class="create-maintenance-ui">
    <b-card class="under-line-title">
      <div class="mb-2">
        <div></div>
        <div class="text-right">
          <div class="report-title d-flex justify-content-between mb-15">
            <h4 class="card-title">
              {{ $t("Maintenance.AddBill") }}
            </h4>
            <b-button
              variant="outline-primary"
              class="v2-back"
              @click="redirectList()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">{{ $t("role.Back") }}</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="card-divider"></div>
      <div class="main-bill">
        <div class="d-flex justify-content-between mobile-d-r mb-15">
          <div class="service-name">{{ maintenance.name }}</div>
          <div class="service-last-time">
            <span>
              {{ $t("Maintenance.LastServicingDate") }} :
              {{ dateFormattedDate }}</span
            >
          </div>
        </div>
        <div
          class="d-flex justify-content-between mobile-d-r mb-15"
          v-if="
            this.maintenance.configuration &&
            this.maintenance.configuration.odometer &&
            this.maintenance.configuration.odometer.enabled
          "
        >
          <div class="service-label">{{ $t("Maintenance.Odometer") }}*</div>
          <div>
            <b-form-input
              id="name"
              v-model="maintenance.odometer"
              class="form-control-merge"
              type="number"
              :min="0"
              name="odometer"
              :placeholder="$t('Maintenance.Odometer')"
            />
          </div>
        </div>
        <div
          v-if="
            this.maintenance.configuration &&
            this.maintenance.configuration.engine &&
            this.maintenance.configuration.engine.enabled
          "
          class="d-flex justify-content-between mobile-d-r mb-15"
        >
          <div class="service-label">{{ $t("Maintenance.EngineHour") }}*</div>
          <div>
            <b-form-input
              id="name"
              type="number"
              v-model="maintenance.engine"
              class="form-control-merge"
              name="EngineHour"
              :min="0"
              :placeholder="$t('Maintenance.EngineHour')"
            />
          </div>
        </div>
        <div
          class="d-flex justify-content-between mobile-d-r mb-15"
          v-if="
            this.maintenance.configuration &&
            this.maintenance.configuration.time &&
            this.maintenance.configuration.time.enabled
          "
        >
          <div class="service-label">
            {{ $t("Maintenance.ActualServiceDateTime") }}*
          </div>
          <div style="min-width: 225px">
            <DatePicker
              :options="{
                is_time: true,
                single_date: true,
                ranges: false,
                opens: 'left'
              }"
              :label="''"
              v-model="maintenance.time"
              :placeholder="$t('Maintenance.ActualServiceDateTime')"
              :minDate="date ? date : null"
            />
          </div>
        </div>
        <div class="checkbox-card-odometer">
          <div class="d-flex justify-content-between mb-15">
            <div class="service-title">
              {{ $t("Maintenance.Services") }}
            </div>

            <div
              class="d-flex justify-content-between"
              style="min-width: 200px"
            >
              <div class="service-title">
                {{ $t("Maintenance.Cost") }}
              </div>
              <div class="service-title">
                {{ $t("Maintenance.Action") }}
              </div>
            </div>
          </div>
          <div
            v-for="(service, index) in maintenance.services"
            :key="index"
            class="d-flex justify-content-between mb-15"
          >
            <div class="service-label">{{ service.type || service.value }}</div>
            <div
              class="d-flex justify-content-between"
              style="min-width: 200px"
            >
              <div>{{ service.cost }}</div>
              <div>
                <feather-icon
                  @click="addService(service)"
                  icon="EditIcon"
                  v-b-tooltip.hover.top="$t('tooTip.update')"
                  class="text-primary action-icon cursor-pointer"
                  size="18"
                />
                <feather-icon
                  icon="Trash2Icon"
                  v-b-tooltip.hover.top="$t('tooTip.delete')"
                  class="text-danger action-icon"
                  size="18"
                  @click="deleteItem(service)"
                />
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between mb-15">
            <div class="service-label">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                class=""
                @click="addService(null)"
              >
                <feather-icon icon="PlusIcon" />{{
                  $t("Maintenance.AddService")
                }}
              </b-button>
            </div>
            <div></div>
          </div>
        </div>
        <div class="checkbox-card-odometer">
          <div class="d-flex justify-content-between mb-15">
            <div class="service-title">
              {{ $t("Maintenance.Documents") }}
            </div>

            <div class="d-flex justify-content-between">
              <div class="service-label">{{ $t("Maintenance.Action") }}</div>
            </div>
          </div>

          <file-upload v-model="maintenance.filesData"></file-upload>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div>
          <b-button
            variant="primary"
            @click="upsertMaintenanceReminder"
            class="btn-icon"
            :disabled="!isValid()"
          >
            <!-- <feather-icon icon="PlusIcon" size="16" /> -->

            <feather-icon icon="SaveIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-sm-inline">{{
              $t("Maintenance.UpdateService")
            }}</span>
          </b-button>
        </div>
      </div>
      <AddServiceCost
        v-if="isHide"
        :isEditable="true"
        @addUpdateService="addUpdateService"
        @close="close"
        :categories="categories"
        :selectedService="selectedService"
      ></AddServiceCost>
      <AreYouSureModal
        :data="{
          ...removeData,
          id: removeData.value,
          modelName: 'modal-sm-remove-maintenance-service',
          name: removeData.type
        }"
        :onClose="onClose"
        :removedUser="removeService"
      ></AreYouSureModal>
      <Loader :show="show" />
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  VBTooltip,
  BFormInput
} from "bootstrap-vue";

import constants from "@/utils/constants";
import Loader from "@/layouts/components/Loader.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import AddServiceCost from "./AddServiceCost.vue";
import MaintenanceService from "@/libs/api/maintenance-service";
import FileUpload from "./FileUpload.vue";
import DateRange from "@/views/Reports/parameterComponent/DateRange.vue";
import DatePicker from "./DatePicker.vue";
import moment from "moment";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    Loader,
    AddServiceCost,
    FileUpload,
    BFormInput,
    DateRange,
    VBTooltip,
    DatePicker,
    AreYouSureModal
  },
  data() {
    return {
      show: false,
      removeData: {},
      maintenance: {
        configuration: {
          odometer: {
            type: "odometer",
            enabled: false,
            interval: "",
            last_value: 0,

            device_sensor: ""
          },
          engine: {
            type: "engine",
            enabled: false,
            interval: "",
            last_value: 0,
            device_sensor: ""
          },
          time: {
            type: "time",
            enabled: true,
            interval: new Date(),
            last_value: null,
            others: {
              date_type: "DAILY",
              start_date: "1",
              repeat: false
            }
          }
        },
        id: "",
        unit_id: "",
        name: "",
        time: "",
        odometer: null,
        engine: null,
        services: [
          {
            type: "",
            value: "",
            cost: 0,
            fields: []
          }
        ],
        filesData: []
      },
      files: [],
      selectedService: null,
      categories: [],
      date: "",
      dateFormattedDate: "",
      isHide: false
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  mounted() {
    this.geMaintenanceReminder(this.$route.params.id);
    this.getAllServiceCategories();
  },
  computed() {},
  watch: {
    maintenance: {
      handler(val) {
        this.isValid();
        if (
          this.maintenance.time &&
          this.date &&
          moment(this.date).isSameOrAfter(this.maintenance.time)
        ) {
          this.maintenance.time = this.date;
        } else {
          this.maintenance.time = this.maintenance.time;
        }
      },
      deep: true
    }
  },
  methods: {
    deleteItem(item) {
      this.removeData = item;
      this.$bvModal.show("modal-sm-remove-maintenance-service");
    },
    redirectList() {
      //this.$router.push({ name: "maintenance-list" });
      this.$router.go(-1);
    },
    numberFormatter(e) {},
    addService(service) {
      if (service) {
        this.selectedService = service;
      } else {
        this.selectedService = null;
      }
      this.isHide = true;
      setTimeout(() => {
        this.$bvModal.show("maintenance-service-cost");
      }, 100);
    },
    async getAllServiceCategories() {
      try {
        this.show = true;
        let response = await new MaintenanceService().getAllServiceCategories();
        this.show = false;
        if (response && response.data) {
          this.categories = response.data.categories.map((item) => {
            return item;
          });
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove-maintenance-service");
    },

    async geMaintenanceReminder(id) {
      try {
        this.show = true;
        let response = await new MaintenanceService().geMaintenanceReminder({
          id
        });
        this.show = false;
        if (response && response.data) {
          const data = response.data;

          this.$route.meta.breadcrumb = [
            ...constants.MAINTENANCE_REMINDERS_BREADCRUMB
          ];
          this.$route.meta.breadcrumb.push(
            ...[
              {
                to: {
                  name: "maintenance-reminder-view",
                  params: { id: id }
                },
                active: true,
                text: response.data.name
              },
              {
                text: "Breadcrumb_Completed",
                active: true
              }
            ]
          );
          if (
            data &&
            data.configuration &&
            data.configuration.time &&
            data.configuration.time.last_value
          ) {
            if (
              data.configuration.time.last_value &&
              data.configuration.time.last_value.startDate &&
              data.configuration.time.last_value.endDate
            ) {
              this.date = data.configuration.time.last_value.startDate;
            } else if (
              data.configuration.time.last_value &&
              typeof data.configuration.time.last_value == "String" &&
              data.configuration.time.last_value.includes("to")
            ) {
              const dates = data.configuration.time.last_value.split(" to ");
              this.date = dates[0];
            } else if (data.configuration.time.last_value) {
              this.date = data.configuration.time.last_value;
            } else {
              this.date = "";
            }
          }
          if (this.date) {
            this.dateFormattedDate = this.formattedDateTime(
              this.date,
              constants.DATE_TIME_FORMAT
            );
          }
          const services = data.services.map((e) => {
            return {
              type: e.type ? e.type : e,
              value: e.value ? e.value : e,
              cost: 0,
              fields: []
            };
          });
          this.maintenance = {
            id: data.id,
            unit_id: data.unit_id,
            name: data.name,
            time: "",
            odometer: null,
            engine: null,

            services: services,
            filesData: [],
            configuration: data.configuration
          };
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    addUpdateService(params) {
      if (params.isEdit) {
        this.maintenance.services.forEach((obj) => {
          if (obj.value === params.service.value) {
            obj.cost = params.service.cost;
            obj.fields = params.service.fields;
          }
        });
        this.$bvModal.hide("maintenance-service-cost");
      } else {
        const service = this.maintenance.services.find(
          (e) => e.value === params.service.value
        );
        if (service && service.value) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Maintenance.ServiceAlreadyExists",
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return;
        }

        this.maintenance.services.push(params.service);
        this.$bvModal.hide("maintenance-service-cost");
      }

      setTimeout(() => {
        this.isHide = false;
      }, 100);
    },
    close() {
      this.$bvModal.hide("maintenance-service-cost");
      setTimeout(() => {
        this.isHide = false;
      }, 100);
    },
    removeService(service) {
      this.maintenance.services = this.maintenance.services.filter(
        (e) => e.value !== service.value
      );
      this.$bvModal.hide("modal-sm-remove-maintenance-service");
    },
    async upsertMaintenanceReminder() {
      try {
        this.show = true;
        let response;
        const data = {
          id: this.maintenance.id,
          unit_id: this.maintenance.unit_id,
          name: this.maintenance.name,
          time: this.maintenance.time,
          odometer: Number(this.maintenance.odometer) || 0,
          engine: Number(this.maintenance.engine) || 0,
          services: this.maintenance.services,
          filesData: this.maintenance.filesData
        };
        response = await new MaintenanceService().updateService({
          ...data,
          id: this.$route.params.id
        });
        const message = this.$t("Maintenance.ServiceReminderUpdateSuccess");

        if (response && response.data) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              text: message,
              icon: "EditIcon",
              variant: "success"
            }
          });
          this.$router.push({ name: "maintenance-reminder-list" });
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    isValid() {
      const isMaintenance = this.maintenance.configuration.odometer.enabled
        ? this.maintenance.odometer
          ? true
          : false
        : true;
      const isEngin =
        this.maintenance.configuration &&
        this.maintenance.configuration.engine &&
        this.maintenance.configuration.engine.enabled
          ? this.maintenance.engine
            ? true
            : false
          : true;
      const isTime = this.maintenance.configuration.time.enabled
        ? this.maintenance.time
          ? true
          : false
        : true;
      if (isMaintenance && isEngin && isTime) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
.main-bill {
  min-height: calc(100vh - 280px);
  max-height: calc(100vh - 280px);
  overflow-y: auto;
  margin-bottom: 10px;
  margin-top: 20px;
  padding: 0px 14x 0px 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.service-name {
  font-family: Montserrat;
  font-size: 17.99px;
  font-weight: 500;
  line-height: 27.8px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
}
.service-last-time {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.59px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
}
.service-label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.59px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
}
.service-title {
  font-family: Montserrat;
  font-size: 17.99px;
  font-weight: 500;
  line-height: 27.8px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
}
.cost-title {
  font-family: Montserrat;
  font-size: 17.99px;
  font-weight: 500;
  line-height: 27.8px;
  letter-spacing: 0.14000000059604645px;
  text-align: left;
}
.checkbox-card-odometer {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 6px;
  margin-bottom: 15px;
  // margin-left: 6px;
}
.dark-layout {
  .checkbox-card-odometer {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background-color: var(--dark-bg);
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 6px;
  }
}
</style>
