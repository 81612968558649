var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"lg","id":"maintenance-service-cost","centered":"","modal-class":"no-header-modal","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":true}},[_c('b-card-text',[_c('div',{staticClass:"maintenance-service-title"},[_vm._v(" "+_vm._s(_vm.$t("Maintenance.ServicesAndCost"))+" ")]),_c('validation-observer',{ref:"serviceRef",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mtt-37"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Maintenance.ServiceName')}},[_c('validation-provider',{attrs:{"name":"type","rules":{
                  required: true
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"vs-icon",class:!_vm.isEditable ? 'disabled' : '',attrs:{"id":"vue-select-1","placeholder":_vm.$t('maintenance.SelectServices'),"options":_vm.categories,"reduce":function (option) { return option.value; },"label":"type","clearable":false,"disabled":_vm.isEdit,"multiple":false},on:{"input":_vm.changeType},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
                var type = ref.type;
                var icon = ref.icon;
return [_c('b-avatar',{staticClass:"align-middle mr-50 unit-filter-avatar",attrs:{"src":icon,"size":"24","variant":"light-primary"}}),_c('span',{staticClass:"unit-search-w"},[_vm._v(" "+_vm._s(type))])]}},{key:"option",fn:function(ref){
                var type = ref.type;
                var icon = ref.icon;
return [_c('b-avatar',{staticClass:"align-middle mr-50",attrs:{"src":icon,"variant":"light-primary","size":"24"}}),_c('span',{staticClass:"unit-search-w"},[_vm._v(_vm._s(type)+" ")])]}}],null,true),model:{value:(_vm.service.type),callback:function ($$v) {_vm.$set(_vm.service, "type", $$v)},expression:"service.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Maintenance.Cost')}},[_c('validation-provider',{attrs:{"name":"cost","rules":{
                  required: true,
                  min_value: 0
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cost","type":"number","min":0},model:{value:(_vm.service.cost),callback:function ($$v) {_vm.$set(_vm.service, "cost", $$v)},expression:"service.cost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.handleError(errors[0]) : ""))])]}}],null,true)})],1)],1)],1),(_vm.service.fields && _vm.service.fields.length)?_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('validation-observer',{ref:"refVariables"},[_c('ParameterAlertComponents',{attrs:{"parameters":_vm.service.fields,"isEditable":true,"component_key":"maintenance"},on:{"validationError":_vm.showValidationError},model:{value:(_vm.service.fields),callback:function ($$v) {_vm.$set(_vm.service, "fields", $$v)},expression:"service.fields"}})],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center mb-64 mtt-50"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","disabled":invalid},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("ReportsData.Save"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }