<template>
  <div>
    <div
      v-for="(document, index) in documents"
      :key="index"
      class="d-flex justify-content-between mb-15"
    >
      <div class="service-label">
        <b-link :href="document.url"> {{ document.file_name }}</b-link>
      </div>
      <div>
        <feather-icon
          icon="Trash2Icon"
          v-b-tooltip.hover.top="$t('tooTip.delete')"
          class="text-danger action-icon"
          size="18"
          @click="removeFile(document.file_id)"
        />
      </div>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-primary"
      class="mb-75 mr-75 custom-upload-button"
    >
      <b-form-file
        class="custom-file-input"
        :placeholder="$t('account.CropPlaceholder')"
        drop-placeholder="Drop file here..."
        accept=".jpg, .png, .jpeg"
        @change="onFileSelect"
      />

      <feather-icon icon="PlusIcon" />{{ $t("Maintenance.UploadMore") }}
    </b-button>
    <Loader :show="showLoading" />
  </div>
</template>

<script>
import {
  BImg,
  BFormGroup,
  BButton,
  BFormFile,
  VBTooltip,
  BLink
} from "bootstrap-vue";

import Loader from "@/layouts/components/Loader.vue";
import Ripple from "vue-ripple-directive";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import FileService from "@/libs/api/file-service";
import { ref } from "@vue/composition-api";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";

export default {
  components: {
    Loader,
    BButton,
    ToastificationContent,
    BFormGroup,
    BImg,
    BFormFile,
    VBTooltip,
    VueCropper,
    BLink
  },
  data() {
    return {
      fileCode: "",
      logoFile: null,
      url: "",
      mime_type: "",
      cropedImage: "",
      autoCrop: false,
      selectedFile: "",
      image: "",
      dialog: false,
      files: "",
      modalShow: false,
      showLoading: false,
      documents: []
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  setup() {
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);

    return {
      refInputEl,
      previewEl,
      inputImageRenderer
    };
  },

  mounted() {},

  props: [],
  methods: {
    removeFile(fileCode) {
      this.documents = this.documents.filter((e) => e.file_id !== fileCode);
      this.$emit(
        "input",
        this.documents.map((e) => e.file_id)
      );
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    downloadLink(url) {
      window.open(url);
    },
    async saveImage(e) {
      const fileName = e.name || Math.random().toString(36).slice(2, 7);

      let newValue = e;
      if (newValue) {
        this.showLoading = true;
        let data = new FormData();
        let file = newValue;

        data.append("name", fileName);
        data.append("file", file);
        new FileService()
          .fileApi(data)
          .then((res) => {
            if (res && res.success) {
              this.fileCode = res.code;
              this.url = res.url;
              this.showLoading = false;
              this.documents.push({
                url: res.url,
                file_id: res.code,
                file_name: fileName
              });
              this.$emit(
                "input",
                this.documents.map((e) => e.file_id)
              );
              this.modalShow = false;
              this.$bvModal.hide("modalShow");
            } else if (res && !res.success && res.err && res.err.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.err.name,
                  text: res.err.message,
                  icon: "EditIcon",
                  variant: "error"
                }
              });
            }
            this.showLoading = false;
          })
          .catch((err) => {
            this.showLoading = false;
            this.showModal = false;
          });
      }
    },
    async cancelImage() {
      this.modalShow = false;
    },

    onFileSelect(e) {
      const file = e.target.files[0];
      this.mime_type = file.type;
      var fileSize = Math.round(file.size / 1024);
      this.saveImage(file);
      // if (fileSize >= constants.MAX_FILE_SIZE_LIMIT * 1024) {
      //   this.$toast({
      //     component: ToastificationContent,
      //     props: {
      //       title: "",
      //       text: this.$t("profile.fileUploadLimitMessage", {
      //         limit: constants.MAX_FILE_SIZE_LIMIT
      //       }),
      //       icon: "InfoIcon",
      //       variant: "danger"
      //     }
      //   });
      //   return;
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
// Overrides user variable
@import "~@core/scss/base/components/include";
.upload-logo {
  .custom-file-label {
    background-color: transparent;
    cursor: pointer;
  }
}
.default-language {
  .vs__dropdown-option--selected {
    .text-body {
      color: #fff !important;
    }
  }
}
.profile-pic {
  width: 160px;
  height: 41px;
}
.default-icon {
  max-height: 80px;
  width: 100%;
}
.custom-file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.icon-position {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 10px; /* Adjust padding as needed */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a transition effect for hover */
}

.icon-overlay:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Hover background color */
}

display-block {
  display: inline-block;
}
.delete-icon {
  position: absolute;
  background-color: #ff4141;
  height: 30px;
  width: 30px;
  right: -15px;
  top: -15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
}
@media only screen and (max-width: 667px) {
  .profile-pic {
    max-width: 100%;
    height: auto;
  }
}
.custom-upload-button {
  position: relative;
  cursor: pointer;
  .custom-file-input.b-form-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
.custom-upload-button {
  position: relative;
  cursor: pointer;
  .custom-file-input.b-form-file {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: $percent_100;
    height: $percent_100;
    opacity: 0;
    cursor: pointer;
  }
  .custom-file-label {
    cursor: pointer;
  }
}
</style>
